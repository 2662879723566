@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
